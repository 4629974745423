import React, { useState, useEffect, useRef } from 'react'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import { path, pathOr } from 'rambdax'
import { connect } from 'react-redux'

import { getSections } from 'businesslayer/api/sections'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { setMinuteStatus } from 'businesslayer/api/minutes'
import { actions } from 'reducers/minuteTakerReducer'
import { EditorSidebarProvider } from 'components/contexts/EditorSidebarConstate'
import MinutesReviewEditor from './MinutesReviewEditor'
import ConfirmationDialog from 'components/shared/ConfirmationDialog'
import i18n from 'i18next'
import { useCommitteesState } from 'components/contexts/committee-context'
import { useReviewsSWR } from 'businesslayer/networkrequests/review/use-reviews-swr'
import { useMinutesDetailSWR } from 'businesslayer/networkrequests/minutes/use-minutes-details-swr'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { RootState } from 'reducers'
import { useCurrentReviewDetails } from 'businesslayer/networkrequests/minutes/use-restricted-reviewers'

const SUGGESTION_REGEX = /<suggestion-start[\s]+([^>]+)>((?:.(?!<\/suggestion>))*.)<\/suggestion-end>/
const SUGGESTION_TAG_REGEX = /<\/?[a-z][^>]*>/gi
const SUGGESTION_TAG = 'suggestion'
export const CKEDITOR_ITEM = '.ck-sidebar-item'

type Props = {
    minutesActions: any
    updateSectionContent: typeof actions.updateSectionContent
    onStatusChange: (status: DocumentStatusKey) => void
} & RouteComponentProps

export interface NormalizedReviewer {
    id: string
    reviewCompleted: boolean
    userId: string
    [key: string]: any
}

function MinutesReviewContainer(props: Props) {
    const [minutesSections, setMinutesSections] = useState([])
    const [userReview, setUserReview] = useState<NormalizedReviewer | null>(null)
    const [showConfirm, setShowConfirm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasEdits, setHasEdits] = useState(true)
    // const [reviewersList, setReviewersList] = useState([])
    const minutesId = path('match.params.minutesId', props) as string
    const { currentUser } = transientStorageManager as any

    // Get Reviews
    const { data: reviewsResponse, mutate: refetchReviews } = useReviewsSWR(minutesId)
    const minutesReviewers = reviewsResponse ?? []

    const { data: minutesDetails } = useMinutesDetailSWR(minutesId)
    const { data: reviewersList, mutate: refetchRestrictedReviews } = useCurrentReviewDetails(
        minutesId
    )
    const minutesAttendees = pathOr([], 'attendees', minutesDetails)
    const currentMinutesTitle = pathOr('', 'title', minutesDetails)

    const currentReview = findCurrentReview(currentUser.id, minutesReviewers)
    let isMounted = useRef(true)
    useEffect(() => {
        if (currentReview) {
            setUserReview(currentReview)
        }
    }, [currentReview])

    useEffect(() => {
        // Get minutes sections
        const loadData = async () => {
            if (isMounted.current) {
                setIsLoading(true)
                const minutesSections = await getSections(minutesId)
                if (isMounted.current) {
                    const sections = sortDataResponse(minutesSections, 'minutesSections')
                    setMinutesSections(sections)
                    setIsLoading(false)
                }
            }
        }
        loadData()
    }, [minutesId])

    // On Mount
    useEffect(() => {
        /**
         * This feels really bad, but doing this on an interval
         * is the only way to check for edits
         */
        const checkForEdits = () => {
            setHasEdits(document.querySelectorAll(CKEDITOR_ITEM).length > 0)
        }

        const interval = setInterval(checkForEdits, 100)

        return () => {
            if (interval) clearInterval(interval)
            isMounted.current = false
        }
    }, [])
    const [loaded, setLoaded] = useState<boolean>(true)
    useEffect(() => {
        if (loaded) {
            setLoaded(false)
            refetchRestrictedReviews()
            refetchReviews()
        }
    }, [refetchRestrictedReviews, loaded, refetchReviews])

    /**
     * Handle refresh of data on add reviewers
     */
    const handleInvitationSent = () => {
        refetchReviews()
    }

    /**
     * Handle refresh of data on mark complete
     * We remount editor with new permission.
     */
    const handleMarkedComplete = () => {
        refetchReviews()
        setLoaded(true)
        remountEditor()
    }

    const remountEditor = () => {
        setIsLoading(true)
        setIsLoading(false)
    }

    /**
     * Save new editor data to backend
     * @param sectionId
     * @param data
     */
    const handleEditorChange = async (sectionId: string, data: string) => {
        if (!!minutesId && typeof data === 'string') {
            props.updateSectionContent({
                id: sectionId,
                minutesId: minutesId,
                reviewContent: data
            })
        }
    }

    /**
     * Loop through all section data from backend
     * Check it against the suggestion regex, if no matches, update the status in the DB
     * If there are matches, throw up a modal
     * @param status
     */
    const handleStatusChange = async (status: DocumentStatusKey) => {
        const selectedMinuteItemId = getSessionStorageItem('selectedMinuteItemId')
        let isValid = true
        const updatedSections = await getSections(selectedMinuteItemId)

        sortDataResponse(updatedSections, 'minutesSections').forEach(
            (section: { reviewHtmlBody?: string }) => {
                if (section.reviewHtmlBody) {
                    const matches = section.reviewHtmlBody.match(SUGGESTION_REGEX)
                    const suggestion_tags = section.reviewHtmlBody
                        .match(SUGGESTION_TAG_REGEX)
                        ?.join('')
                        ?.includes(SUGGESTION_TAG)
                    if (matches) {
                        isValid = matches.length === 0
                    }
                    if (isValid && suggestion_tags) {
                        isValid = !isValid
                    }
                }
            }
        )

        if (isValid) {
            await setMinuteStatus(selectedMinuteItemId, status, null)
            props.onStatusChange(status)
            refetchRestrictedReviews()
        } else {
            setShowConfirm(true)
        }
    }

    const sortDataResponse = (details, dataKey: string) => {
        if (!details || !details.sort || !details[dataKey]) {
            return []
        }

        return details.sort.map((id: string) => {
            return { id: id, ...details[dataKey][id].attributes }
        })
    }

    const handleActionReorder = () => {}
    const handleActionChecked = () => {}
    const handleEditAction = () => {}
    const handleDeleteAction = () => {}
    const handleViewAllActions = () => {}

    const { currentCommitteeRole } = useCommitteesState()

    return (
        <EditorSidebarProvider>
            {showConfirm && (
                <ConfirmationDialog
                    message={i18n.t('INVALID_REVIEW_BODY')}
                    title={i18n.t('INVALID_REVIEW_TITLE')}
                    isActionRequired={true}
                    onCancel={() => setShowConfirm(false)}
                    onConfirm={() => setShowConfirm(false)}
                    confirmLabel={i18n.t('OK')}
                    hideCancel
                />
            )}
            <MinutesReviewEditor
                hasEdits={hasEdits}
                loading={isLoading}
                onEditorChange={handleEditorChange}
                minutesId={minutesId}
                attendees={minutesAttendees}
                minutesSections={minutesSections}
                minutesActions={props.minutesActions}
                minutesReviewers={minutesReviewers}
                viewAsRole={currentCommitteeRole}
                onActionReorder={handleActionReorder}
                onActionChecked={handleActionChecked}
                onEditAction={handleEditAction}
                onDeleteAction={handleDeleteAction}
                onViewAllActions={handleViewAllActions}
                minutesTitle={currentMinutesTitle}
                onInvitationSent={handleInvitationSent}
                onMarkedComplete={handleMarkedComplete}
                currentReview={userReview}
                onStatusChange={handleStatusChange}
                reviewersList={reviewersList ?? []}
                setLoaded={setLoaded}
                setIsLoading={setIsLoading}
            />
        </EditorSidebarProvider>
    )
}

/**
 * Helper for finding the current user's review based on their ID
 * @param currentUserId
 * @param reviewers
 */
const findCurrentReview = (
    currentUserId: string,
    reviewers: any //{ [key: string]: NormalizedReviewer }
) => {
    const currentReview = Object.keys(reviewers).find(
        (id) => reviewers[id].userId === String(currentUserId)
    )
    if (currentReview) {
        return reviewers[currentReview]
    }
    return null
}

const mapStateToProps = (_state: RootState, _) => {
    return {}
}

export default connect(mapStateToProps, {
    updateSectionContent: actions.updateSectionContent,
    updateReviewers: actions.updateReviewers
})(withRouter(MinutesReviewContainer))
