import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './businesslayer/store'
import { Route, Switch } from 'react-router-dom'
import { RouteStateProvider } from 'components/contexts/route-context/route-context'
import { MinutesManagerPage } from 'pages/minutes-manager-page/minutes-manager-page'
import MinutesDetailPage from 'pages/MinutesDetail'
import NotFound from 'pages/NotFound'
import { MinutesAttendanceReportPage } from 'pages/minutes-attendance-report/minutes-attendance-report'
import { useChatAiToken } from 'minutes-ai-help-chat/hooks/useChatAiToken'
import { connect } from 'react-redux'
import selectors from 'selectors/minuteAttendeesSelectors'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { actions } from 'reducers/minutesAttendeesReducer'
import { AiChatTokenProps } from 'components/minutetaker/components/InvitationView/types'
import LeftNavBar from 'components/minutetaker/components/LeftNavBar'
import { Box } from '@material-ui/core'
import ActionDashboard from 'components/minutetaker/components/ActionDashboard'
import i18n from 'common/util/internationalization'
import DocError from 'pages/DocError'

/**
 * Application Routes
 */

const AdminRoute = ({ component: Component, isProMember, ...rest }) => (
    <Route {...rest} render={(props) => (isProMember ? <Component {...props} /> : <NotFound />)} />
)

const Routes = (props: AiChatTokenProps) => {
    const isProMember = true //blc pro member logic will be added here
    useChatAiToken(props)
    const title = i18n.t('NOT_REVIWER')
    const docError = i18n.t('DOCUMENT_STATUS_CHANGED')
    const urlExpired = i18n.t('URL_EXPIRED')
    const urlNotExist = i18n.t('CONTACT_SUPPORT')
    const docNotExist = i18n.t('NO_LONGER_ACCESS')
    const docNotAvailable = i18n.t('CONTACT_ADMIN')

    return (
        <ConnectedRouter history={history}>
            <RouteStateProvider>
                <Box
                    style={{
                        display: 'flex',
                        maxWidth: '100vw'
                    }}>
                    <LeftNavBar />
                    <Switch>
                        <Route path="/not-found" isNotFound={true} component={NotFound} />
                        <Route
                            path="/unauthorize"
                            render={(props) => <DocError {...props} title={title} />}
                        />
                        <Route
                            path="/doc-not-exist"
                            render={(props) => (
                                <DocError
                                    {...props}
                                    title={docNotExist}
                                    subTitle={docNotAvailable}
                                />
                            )}
                        />
                        <Route
                            path="/url-not-exist"
                            render={(props) => (
                                <DocError {...props} title={urlExpired} subTitle={urlNotExist} />
                            )}
                        />
                        <Route
                            path="/docError"
                            render={(props) => <DocError {...props} title={docError} />}
                        />
                        <Route exact path="/" component={MinutesManagerPage} />
                        <Route exact path="/taker/:minutesId" component={MinutesDetailPage} />
                        <Route path="/taker/:minutesId/actions" component={MinutesDetailPage} />
                        <AdminRoute
                            exact
                            path="/attendanceReport"
                            isProMember={isProMember}
                            component={MinutesAttendanceReportPage}
                        />
                        <AdminRoute
                            exact
                            path="/actionDashboard"
                            isProMember={isProMember}
                            component={ActionDashboard}
                        />
                        <Route path="/*" component={NotFound} />
                    </Switch>
                </Box>
            </RouteStateProvider>
        </ConnectedRouter>
    )
}
const mapStateToProps = (state, _) => {
    return {
        identityToken: selectors.getIdentityToken(state.minutesAttendeesReducer)
    }
}
const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(Routes)
