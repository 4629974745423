/** @jsx jsx */
import { useState } from 'react'
import { jsx, css } from '@emotion/react'
import DomHelper from 'components/helpers/DomHelper'

import MinutesReviewEditorList from '../sectionlist/MinutesReviewEditorList'
import MinutesReviewToolbar from '../components/MinutesReviewToolbar'
import { NormalizedReviewer } from './MinutesReviewContainer'
import { DocumentDetailsLayout } from 'assets/styles/LayoutConstants'
import MinutesReviewSidebar from '../components/MinutesReviewSidebar/MinutesReviewSidebar'
import { EditorToolbar } from 'components/shared/Toolbar'
import { connect } from 'react-redux'
import minuteTakerSelectors from 'selectors/minuteTakerSelectors'
import { bindActionCreators } from 'redux'
import { actions } from 'reducers/minuteTakerReducer'
import MinutesActions from '../components/MinutesActions'
import { EDITOR_TABS } from '../components/InvitationView/types'
import LinearIndeterminate from 'components/shared/LinearProgress'

type Props = {
    setIsLoading: any
    minutesId: string
    attendees: any[]
    minutesSections: any[]
    minutesActions: any
    minutesReviewers: any
    viewAsRole: UserRole
    onActionReorder: any
    onActionChecked: any
    onEditAction: any
    onDeleteAction: any
    onViewAllActions: any
    minutesTitle: string
    currentReview: NormalizedReviewer | null
    loading?: boolean
    hasEdits?: boolean
    onEditorChange: (sectionId: string, data: string) => void
    onStatusChange: (status: DocumentStatusKey) => void
    onInvitationSent: () => void
    onMarkedComplete: () => void
    reviewersList: PersonChip[]
    setLoaded: React.Dispatch<React.SetStateAction<boolean>>
    showSideBar: boolean
    status: string
    currentMinuteItem: any
    currentSelectedTab: string
}

export function MinutesReviewEditor(props: Props) {
    const [isToolbarLabelsCollapsed, setIsToolbarLabelsCollapsed] = useState(false)
    const [isAllExpanded, setIsAllExpanded] = useState(true)

    const {
        attendees,
        minutesSections,
        minutesActions,
        minutesReviewers,
        viewAsRole,
        minutesTitle,
        onInvitationSent,
        currentReview,
        onMarkedComplete,
        hasEdits,
        reviewersList,
        setLoaded,
        showSideBar
    } = props

    const onResize = (evt) => {
        setIsToolbarLabelsCollapsed(evt.width < 1000)
    }

    return (
        <div
            css={css`
                display: flex;
                flex-flow: column;
                height: 100%;
                position: relative;
                height: calc(100vh - 64px);
            `}
            data-testid="minutes-review-editor">
            <MinutesReviewToolbar
                onMarkedComplete={onMarkedComplete}
                userRole={viewAsRole}
                minutesId={props.minutesId}
                isToolbarLabelsCollapsed={isToolbarLabelsCollapsed}
                onToggleExpandAll={setIsAllExpanded}
                minutesTitle={minutesTitle}
                currentReview={currentReview}
                onStatusChange={props.onStatusChange}
                isInvited={viewAsRole === 'DIRECTOR' || (currentReview && currentReview.isInvited)}
            />
            <div css={toolbarWrapperStyle}>
                <EditorToolbar />
            </div>
            {<LinearIndeterminate loading={props.loading} />}
            <div css={wrapperClass} data-testid="ck-editor-list-section">
                {props.currentSelectedTab === EDITOR_TABS.ACTIONS ? (
                    <div style={{ minWidth: 'calc(100vw - 780px)', width: '100%' }}>
                        <MinutesActions
                            showSideBar={showSideBar}
                            setIsLoading={props.setIsLoading}
                        />
                    </div>
                ) : (
                    <div css={contentClass}>
                        <MinutesReviewEditorList
                            onEditorChange={props.onEditorChange}
                            minutesId={props.minutesId}
                            attendees={attendees}
                            minutesSections={minutesSections}
                            actionItems={minutesActions}
                            onActionReorder={props.onActionReorder}
                            onActionChecked={props.onActionChecked}
                            onEditAction={props.onEditAction}
                            onDeleteAction={props.onDeleteAction}
                            onViewAllActions={props.onViewAllActions}
                            isAllExpanded={isAllExpanded}
                            reviewers={minutesReviewers}
                            role={viewAsRole}
                            loading={props.loading}
                            reviewComplete={!!currentReview && currentReview.reviewCompleted}
                            reviewCompleted={!!currentReview && currentReview.reviewCompleted}
                            isInvited={currentReview && currentReview.isInvited}
                        />
                    </div>
                )}
                <div
                    css={
                        showSideBar && props.currentSelectedTab === EDITOR_TABS.EDITOR
                            ? sidebarClass
                            : css`
                                  display: none;
                              `
                    }>
                    <MinutesReviewSidebar
                        onInvitationSent={onInvitationSent}
                        minutesTitle={minutesTitle}
                        minutesId={props.minutesId}
                        minutesSections={minutesSections}
                        role={viewAsRole}
                        reviewers={minutesReviewers}
                        hasEdits={hasEdits}
                        reviewersList={reviewersList}
                        setLoaded={setLoaded}
                    />
                </div>
            </div>
            <DomHelper onResize={onResize} />
        </div>
    )
}

const mapStateToProps = (state, _) => {
    return {
        showSideBar: minuteTakerSelectors.showSideBar(state.minuteTakerReducer),
        currentMinuteItem: minuteTakerSelectors.currentMinuteItem(state.minuteTakerReducer),
        currentSelectedTab: minuteTakerSelectors.currentSelectedTab(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

const DOCUMENT_MIN_WIDTH = DocumentDetailsLayout.document.minWidth
const SIDEBAR_MIN_WIDTH = DocumentDetailsLayout.reviewSidebar.minWidth
const SIDEBAR_MAX_WIDTH = DocumentDetailsLayout.reviewSidebar.maxWidth

const wrapperClass = css`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: stretch;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    @media (max-width: 1024px) {
        max-width: 100vw;
    }
`
const contentClass = css`
    display: flex;
    flex: 4 0 ${DOCUMENT_MIN_WIDTH}px;
    justify-content: center;
    align-items: flex-start;
    // max-height: 80vh;
    overflow: auto;
    margin-top: 16px;
    height: 100%;
`
const sidebarClass = css`
    flex: 1 0 ${SIDEBAR_MIN_WIDTH}px;
    max-width: ${SIDEBAR_MAX_WIDTH}px;

    height: 100%;
    justify-content: flex-end;
    padding: 0px 0px 0px 16px;
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    @media (max-width: 1025px) {
        max-width: 300px;
        max-height: calc(100vh - 240px);
    }
`
const toolbarWrapperStyle = css`
    margin-top: 40px;
`

export default connect(mapStateToProps, mapDispatchToProps)(MinutesReviewEditor)
