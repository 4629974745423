import React, { useState } from 'react'
import { StyledLogoWrapper } from 'components/shared/LockedModal'
import DiligentErrorIcon from 'assets/icons/DiligentErrorIcon'
import Button from '@material-ui/core/Button'
import { Box, makeStyles } from '@material-ui/core'
import styled from '@emotion/styled'
import Modal from 'react-modal'
import { ColorButtonHover } from 'assets/styles/variables'
import i18n from 'i18next'
import { Redirect } from 'react-router-dom'

export const StyledBody = styled('section')`
    color: #2a455a;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-family: Source Sans Pro;
    color: #1e1e1e;
`
export const StyledModal = styled(Modal)`
    position: absolute;
    top: 50%;
    gap: 48px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: 0;
`

const customStyles = {
    overlay: {
        backgroundColor: '#FFFFFF',
        zIndex: 1000
    }
}

const sessionExpiredTextTitle: React.CSSProperties = {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '32px',

    display: 'flex',
    justifyContent: 'center',
    maxWidth: '720px',
    margin: '0 auto',
    textAlign: 'center' as 'center'
}

const sessionExpiredText = {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '32px',

    display: 'flex',
    justifyContent: 'center'
}

const useStyles = makeStyles(() => ({
    customButton: {
        backgroundColor: `#455D82`,
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        height: '40px',
        borderRadius: '2px',
        textAlign: 'center',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: `${ColorButtonHover}`
        },
        color: '#FFFFFF'
    }
}))

interface DocErrorProps {
    title?: string
    subTitle?: string
}

const DocError: React.FunctionComponent<DocErrorProps> = ({ title, subTitle }) => {
    const [navigatingOut, setNavigatingOut] = useState(false)
    const classes = useStyles()

    if (navigatingOut) {
        return (
            <Redirect
                to={{
                    pathname: '/',
                    state: {}
                }}
            />
        )
    }
    return (
        <StyledModal isOpen={true} style={customStyles}>
            <StyledLogoWrapper>
                <DiligentErrorIcon />
            </StyledLogoWrapper>
            <StyledBody>
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <div style={sessionExpiredTextTitle}>{title}</div>
                    {subTitle && <div style={sessionExpiredText}>{subTitle}</div>}
                </Box>
            </StyledBody>
            <Box display="flex" justifyContent="center">
                <Button className={classes.customButton} onClick={() => setNavigatingOut(true)}>
                    {i18n.t('NOT_FOUND_LINK')}
                </Button>
            </Box>
        </StyledModal>
    )
}

export default DocError
