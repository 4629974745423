import React, { useEffect, useState } from 'react'
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom'

//================ Components ===============================

import LinearIndeterminate from 'components/shared/LinearProgress'
import { StyledBody, StyledModal } from 'pages/DocError/DocError'
import { StyledLogoWrapper } from 'components/shared/LockedModal'
import DiligentErrorIcon from 'assets/icons/DiligentErrorIcon'
import { Box, Button, makeStyles } from '@material-ui/core'
import i18n from 'common/util/internationalization'
import { ColorButtonHover, ColorEastBay } from 'assets/styles/variables'

const useStyles = makeStyles(() => ({
    customButton: {
        backgroundColor: `${ColorEastBay}`,
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        height: '40px',
        borderRadius: '2px',
        textAlign: 'center',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: `${ColorButtonHover}`
        },
        color: '#FFFFFF'
    }
}))

const customStyles = {
    overlay: {
        backgroundColor: '#FFFFFF',
        zIndex: 1000
    }
}

const sessionExpiredTextTitle = {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center'
}

const sessionExpiredText = {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px'
}

function NotFound(_: RouteComponentProps) {
    const [navigatingOut, setNavigatingOut] = useState(false)
    const isReloaded = sessionStorage.getItem('reloaded') === 'false'
    const classes = useStyles()
    useEffect(() => {
        if (isReloaded) {
            sessionStorage.setItem('reloaded', 'true')
            window.location.reload()
        }
    }, [isReloaded])

    if (navigatingOut) {
        return (
            <Redirect
                to={{
                    pathname: '/',
                    state: {}
                }}
            />
        )
    }

    if (isReloaded) {
        return <LinearIndeterminate loading={true} />
    }
    return (
        <StyledModal isOpen={true} style={customStyles}>
            <StyledLogoWrapper>
                <DiligentErrorIcon />
            </StyledLogoWrapper>
            <StyledBody>
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <div style={sessionExpiredTextTitle}>{i18n.t('NOT_FOUND')}</div>
                    <div style={sessionExpiredText}>
                        <p
                            style={{
                                textAlign: 'center'
                            }}>
                            {i18n.t('NOT_FOUND_PAGE_MESSAGE')}
                        </p>
                        <p
                            style={{
                                textAlign: 'center'
                            }}>
                            {i18n.t('NOT_FOUND_PAGE_MESSAGE_SUGGESTION')}
                        </p>
                    </div>
                </Box>
            </StyledBody>
            <Box display="flex" justifyContent="center">
                <Button className={classes.customButton} onClick={() => setNavigatingOut(true)}>
                    {i18n.t('NOT_FOUND_LINK')}
                </Button>
            </Box>
        </StyledModal>
    )
}
//#endregion

export default withRouter(NotFound)
