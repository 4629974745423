import { Step, StepConnector, StepLabel, Stepper, makeStyles, withStyles } from '@material-ui/core'
import { ColorBaseDarkGray, ColorBaseWhite } from 'assets/styles/variables'
import { translate } from 'components/shared/internationalization'
import React from 'react'

type Props = {
    activeStep: number
}
const getSteps = () =>
    ([
        translate('REVIEWER_TITLE'),
        translate('REVIEWER_DUE_DATE'),
        translate('REVIEWER_EMAIL')
    ] as unknown) as string[]
const useStyles = makeStyles(() => ({
    activestep: {
        fontFamily: 'Source Sans Pro',
        color: '#455D82 !important',
        '&.MuiStepIcon-active circle': {
            fill: '#455D82 !important'
        },
        '& .MuiStepIcon-text': {
            fill: `${ColorBaseWhite} !important`
        }
    },
    stepIconText: {
        fontFamily: 'Source Sans Pro',
        '& circle': {
            fill: `${ColorBaseDarkGray} !important`
        },
        '& .MuiStepIcon-text': {
            fill: `${ColorBaseWhite} !important`
        },
        '& .MuiTypography-body2': {
            fontFamily: 'Source Sans Pro'
        }
    },
    Stepper: {
        fontFamily: 'Source Sans Pro',
        padding: '10px 0',
        paddingLeft: '0',
        maxWidth: '600px !important',
        '& .MuiStepLabel-active': {
            fontWeight: 600
        },
        '& .MuiStepLabel-completed': {
            fontWeight: 400
        },
        '& > *': {
            fontFamily: 'Source Sans Pro'
        }
    },
    firstStep: {
        fontFamily: 'Source Sans Pro',
        paddingLeft: '0 !important'
    }
}))
const LineConnector = withStyles({
    line: {
        borderColor: '#949494',
        width: '70%',
        margin: 'auto'
    }
})(StepConnector)
const StepperComponent = ({ activeStep }: Props) => {
    const steps = getSteps()
    const classes = useStyles()
    return (
        <Stepper activeStep={activeStep} className={classes.Stepper} connector={<LineConnector />}>
            {steps.map((label, index) => (
                <Step
                    key={index}
                    completed={activeStep > index}
                    className={index === 0 ? classes.firstStep : classes.stepIconText}>
                    <StepLabel
                        StepIconProps={{
                            classes: { active: classes.activestep, completed: classes.activestep }
                        }}>
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

export default StepperComponent
