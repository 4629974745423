import React from 'react'

import { MinuteActionsContainer as ActionsContainer } from '../minutetaker/sectionlist/MinuteActions/MinuteActionsContainer'
import MinutesEditor from 'components/minutetaker/components/MinutesEditor'
import { css, ClassNames } from '@emotion/react'
import { DefualtToolbar, ToolbarOptions } from 'assets/ckeditor/toolbars'
import { Editor } from 'components/contexts/editor'
import { fetchAiSupport } from 'businesslayer/networkrequests/AIService'

//region  Styles
//endregion

//region  Props

type Props = {
    readonly?: boolean
    sectionId: string
    actionItems: Array<any>
    isOpened: boolean
    editorData: string
    onActionReorder: (...args: any[]) => void
    onActionChecked: (...args: any[]) => void
    onEditAction: (...args: any[]) => void
    onDeleteAction: (...args: any[]) => void
    onViewAllActions: (...args: any[]) => void
    onEditorClicked: (sectionId: string) => void
    onEditorDataChanged(editor: Editor, sectionId): void
    style?: any
}

//endregion

//region Implementation

export function MinuteEditorArea(props: Props) {
    function handleEditorClicked() {
        props.onEditorClicked(props.sectionId)
    }
    const handlefetchAiSupport = async (content) => {
        return await fetchAiSupport(
            JSON.stringify({
                section_id: props.sectionId,
                content
            })
        )
    }
    const containerClss = css`
        margin-top: 0;
        padding-left: 0;
        background: #fff;
        display: ${props.isOpened ? 'inherit' : 'none'};
    `
    /*
     * Chanenged the custom save method to autosave plugin of ckeditor
     * Waitng time is 2 seconds after that it will save the data
     */
    function editor(sectionId) {
        return (
            <MinutesEditor
                readonly={props.readonly}
                /*onChange={(editor) => {
                    return props.onEditorDataChanged(editor, sectionId)
                }}*/
                data={props.editorData}
                editorConfig={{
                    toolbar: DefualtToolbar.items,
                    highlight: ToolbarOptions.highlight,
                    autosave: {
                        waitingTime: 2000,
                        save(editor: any) {
                            return props.onEditorDataChanged(editor, sectionId)
                        }
                    }
                }}
                getAiSupport={handlefetchAiSupport}
            />
        )
    }
    return (
        <ClassNames>
            {({ css }) => (
                <div
                    className={css`
                        ${containerClss}
                    `}
                    onClick={handleEditorClicked}>
                    <section
                        className={css`
                            min-height: 75px;
                            overflow-wrap: break-word;
                        `}>
                        {/* Header / Layout and Draft Editor */}
                        {Number(props.sectionId) ? editor(props.sectionId) : null}
                    </section>
                    <ActionsContainer
                        readonly={props.readonly}
                        actionItems={props.actionItems}
                        onActionReorder={props.onActionReorder}
                        onActionChecked={props.onActionChecked}
                        onEditAction={props.onEditAction}
                        onDeleteAction={props.onDeleteAction}
                        onViewAllActions={props.onViewAllActions}
                    />
                </div>
            )}
        </ClassNames>
    )
}

//endregion

//region Export

export default MinuteEditorArea

//endregion
