import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery
} from '@material-ui/core'

import NavMenuIcon from 'assets/icons/NavMenuIcon'
import HomeIcon from 'assets/icons/HomeIcon'
import AttendanceReportingIcon from 'assets/icons/AttendanceReportingIcon'
import { AtlasIcon } from '@diligentcorp/atlas-react'
import { AtlasWhite_100Var, ColorBaseMulberryBlue, ColorBaseWhite } from 'assets/styles/variables'
import DiligentIcon from 'assets/icons/DiligentIcon'
import DiligentBrandIcon from 'assets/icons/DiligentBrandIcon'
import { useHistory } from 'react-router'
import { getHelpURL } from 'components/layout/page-header-helpers'
import i18n from 'common/util/internationalization'
import SkipToContent from 'components/layout/SkipToContent'
import ActionDashboardIcon from 'assets/icons/ActionDashboardIcon'
import emitter from 'common/util/events'

const drawerWidthOpen = 300
const drawerWidthClosed = 64

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 'none',
        background: '#2f3b4d',
        height: '100vh',
        transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        width: drawerWidthClosed
    },
    menuContainer: {
        display: 'inline-flex',
        height: '64px',
        [theme.breakpoints.between(0, 1367)]: {
            position: window.screen.width < 1500 ? 'fixed' : 'static',
            zIndex: theme.zIndex.drawer
        }
    },
    navBarContentToggleFocus: {
        margin: '0 auto',
        height: '64px',
        '&:focus': {
            outline: 'none'
        }
    },
    list: {
        width: '100%',
        maxWidth: drawerWidthOpen,
        padding: '0px 6px'
    },
    listItemText: {
        transition: theme.transitions.create(['opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        opacity: 1,
        color: '#ffffff',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        textTransform: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '12px'
    },
    listItemTextHidden: {
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out'
    }, // Add a comma here
    listItem: {
        borderRadius: '10px',
        height: '48px',
        margin: '5px 0'
    },
    listIcon: {
        minWidth: '35px',
        display: 'flex'
    },
    listIconClosed: {
        minWidth: '25px',
        display: 'flex',
        justifyContent: 'center'
    },
    divider: {
        backgroundColor: '#A0A2A5',
        height: '0.5px'
    },
    selected: {
        backgroundColor: '#455D82'
    },
    selectedButtonFocus: {
        height: '48px',
        '&:focus': {
            height: '48px',
            outline: 'none',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    }, // Add a colon here
    empty: {
        width: drawerWidthClosed
    },
    drawer: {
        width: 'auto',
        maxWidth: drawerWidthOpen
    },
    drawerPaper: {
        maxWidth: drawerWidthOpen
    },
    nonClickable: {
        cursor: 'default'
    }
}))

const LeftNavBar: React.FC = () => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const helpURL = getHelpURL()
    const [canAddMinutes, setCanAddMinutes] = useState(false)

    const isTabletOrMobile = useMediaQuery('(max-width: 1367px)') && window.screen.width < 1500
    const history = useHistory()
    const selectedPath = useMemo(() => {
        switch (history.location.pathname) {
            case '/attendanceReport':
                return 'attendanceReport'
            case '/actionDashboard':
                return 'actionDashboard'
            default:
                return 'home'
        }
    }, [history.location.pathname])
    const handleToggleSidebar = useCallback(() => {
        setOpen(!open)
    }, [open])
    const listItemClass = useMemo(
        () =>
            open ? classes.listItemText : `${classes.listItemText} ${classes.listItemTextHidden}`,
        [open, classes]
    )
    const handleListItemClick = useCallback(
        (itemName: string) => {
            if (itemName.includes('http')) {
                window.open(itemName, '_blank')
            } else {
                history.push(itemName)
            }
            setOpen(false)
        },
        [history, setOpen]
    )
    useEffect(() => {
        emitter.on('canAddMinutes', () => {
            setCanAddMinutes(true)
        })
        return () =>
            emitter.off('canAddMinutes', () => {
                setCanAddMinutes(false)
            })
    }, [canAddMinutes, setCanAddMinutes])

    const drawerMode = useMemo(
        () => (
            <Box
                className={classes.root}
                style={{ width: open ? drawerWidthOpen : drawerWidthClosed }}>
                {!isTabletOrMobile ? (
                    <>
                        <SkipToContent />
                        <Box className={classes.menuContainer}>
                            <Button
                                data-analytics="PrimaryNavigation-ExpandContract"
                                className={classes.navBarContentToggleFocus}
                                disableFocusRipple
                                onClick={handleToggleSidebar}
                                role="button"
                                data-testid="sidebar-toggle-button"
                                aria-label={open ? i18n.t('OPEN_SIDEBAR') : i18n.t('CLOSE_SIDEBAR')}
                                aria-expanded={open ? 'true' : 'false'}>
                                <IconButton
                                    aria-label={i18n.t('TOGGLE_MENU')}
                                    title={i18n.t('TOGGLE_MENU')}
                                    className={classes.selectedButtonFocus}>
                                    <NavMenuIcon />
                                </IconButton>
                            </Button>
                            <div
                                tabIndex={-1}
                                className={`${listItemClass} ${classes.nonClickable}`}
                                aria-label={i18n.t('DILIGENT_LOGO')}>
                                <DiligentBrandIcon />
                            </div>
                        </Box>
                    </>
                ) : (
                    <Box style={{ display: 'inline-flex' }}>
                        <Button
                            data-analytics="PrimaryNavigation-ExpandContract"
                            className={classes.navBarContentToggleFocus}
                            disableFocusRipple
                            onClick={handleToggleSidebar}
                            role="button"
                            data-testid="sidebar-toggle-button"
                            aria-label={open ? i18n.t('OPEN_SIDEBAR') : i18n.t('CLOSE_SIDEBAR')}
                            aria-expanded={open ? 'true' : 'false'}>
                            <IconButton
                                aria-label={i18n.t('TOGGLE_MENU')}
                                title={i18n.t('TOGGLE_MENU')}
                                className={classes.selectedButtonFocus}>
                                <NavMenuIcon />
                            </IconButton>
                        </Button>
                        <div tabIndex={-1} className={`${listItemClass} ${classes.nonClickable}`}>
                            <DiligentBrandIcon />
                        </div>
                    </Box>
                )}
                <Divider className={classes.divider} light />
                <List className={classes.list}>
                    {!open && (
                        <ListItem button className={classes.listItem}>
                            <div
                                aria-label={i18n.t('DILIGENT_LOGO')}
                                title={i18n.t('DILIGENT_LOGO')}
                                tabIndex={-1}
                                style={{ pointerEvents: 'none' }}
                                className={open ? classes.listIcon : classes.listIconClosed}>
                                <DiligentIcon />
                            </div>
                        </ListItem>
                    )}
                    <ListItem
                        button
                        data-analytics="PrimaryNavigation-Home"
                        aria-label={i18n.t('HOME_MINUTES')}
                        data-testid="Home (Minutes)"
                        className={`${classes.listItem} ${
                            selectedPath === 'home' && classes.selected
                        } ${classes.selectedButtonFocus}`}
                        onClick={() => handleListItemClick('/')}>
                        <ListItemIcon
                            title={i18n.t('HOME_MINUTES')}
                            className={open ? classes.listIcon : classes.listIconClosed}>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t('HOME_MINUTES')} className={listItemClass} />
                    </ListItem>
                    {canAddMinutes && (
                        <ListItem
                            button
                            data-analytics="PrimaryNavigation-AttendanceReporting"
                            aria-label={i18n.t('ATTENDANCE_REPORTING')}
                            data-testid="attendance-reporting"
                            className={`${classes.listItem && classes.selectedButtonFocus} ${
                                selectedPath === 'attendanceReport' && classes.selected
                            }`}
                            onClick={() => handleListItemClick('/attendanceReport')}>
                            <ListItemIcon
                                title={i18n.t('ATTENDANCE_REPORTING')}
                                className={open ? classes.listIcon : classes.listIconClosed}>
                                <AttendanceReportingIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={i18n.t('ATTENDANCE_REPORTING')}
                                className={listItemClass}
                            />
                        </ListItem>
                    )}
                    {canAddMinutes && (
                        <ListItem
                            button
                            data-analytics="PrimaryNavigation-ActionDashboard"
                            aria-label={i18n.t('ACTION_DASHBOARD')}
                            data-testid="action_dashboard"
                            className={`${classes.listItem} ${
                                selectedPath === 'actionDashboard' && classes.selected
                            } ${classes.selectedButtonFocus}`}
                            onClick={() => handleListItemClick('/actionDashboard')}>
                            <ListItemIcon
                                title={i18n.t('ACTION_DASHBOARD')}
                                className={open ? classes.listIcon : classes.listIconClosed}>
                                <ActionDashboardIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={i18n.t('ACTION_DASHBOARD')}
                                className={listItemClass}
                            />
                        </ListItem>
                    )}
                    <Divider className={classes.divider} light variant="fullWidth" />
                    <ListItem
                        button
                        data-analytics="PrimaryNavigation-HelpAndSupport"
                        aria-label={i18n.t('HELP_AND_SUPPORT')}
                        data-testid="help-and-support"
                        className={classes.listItem && classes.selectedButtonFocus}
                        onClick={() => handleListItemClick(helpURL)}>
                        <ListItemIcon
                            title={i18n.t('HELP_AND_SUPPORT')}
                            className={open ? classes.listIcon : classes.listIconClosed}>
                            <AtlasIcon name="help" color={AtlasWhite_100Var} />
                        </ListItemIcon>
                        <ListItemText
                            primary={i18n.t('HELP_AND_SUPPORT')}
                            className={listItemClass}
                        />
                    </ListItem>
                </List>
            </Box>
        ),
        [
            open,
            classes,
            listItemClass,
            selectedPath,
            handleListItemClick,
            helpURL,
            handleToggleSidebar,
            isTabletOrMobile,
            canAddMinutes
        ]
    )
    return isTabletOrMobile ? (
        <>
            <SkipToContent />
            <Box className={classes.menuContainer} role="navigation" data-testid="menu-container">
                <Button
                    data-analytics="PrimaryNavigation-ExpandContract"
                    className={classes.navBarContentToggleFocus}
                    disableFocusRipple
                    onClick={handleToggleSidebar}
                    role="button"
                    aria-label={open ? i18n.t('OPEN_SIDEBAR') : i18n.t('CLOSE_SIDEBAR')}
                    title={i18n.t('TOGGLE_MENU')}
                    aria-expanded={open ? 'true' : 'false'}
                    data-testid="sidebar-toggle-button">
                    <IconButton
                        className={classes.selectedButtonFocus}
                        title={i18n.t('TOGGLE_MENU')}
                        aria-label={i18n.t('TOGGLE_MENU')}
                        data-testid="nav-menu-icon-button">
                        <NavMenuIcon />
                    </IconButton>
                </Button>
                <div
                    tabIndex={-1}
                    className={`${listItemClass} ${classes.nonClickable}`}
                    data-testid="diligent-brand-icon"
                    title={i18n.t('DILIGENT_LOGO')}
                    aria-label={i18n.t('DILIGENT_LOGO')}>
                    <DiligentBrandIcon />
                </div>
            </Box>
            <Drawer
                open={open}
                className={classes.drawer}
                classes={{ paper: classes.drawerPaper }}
                onClose={handleToggleSidebar}
                aria-label={i18n.t('DRAWER')}
                data-testid="drawer"
                role="navigation">
                {drawerMode}
            </Drawer>
        </>
    ) : (
        drawerMode
    )
}

export default LeftNavBar
